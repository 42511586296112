/**
 * Преобразует миллисекунды в строку времени в формате "MM:SS".
 *
 * @param {number} ms - Длительность времени в миллисекундах.
 * @returns {string} Отформатированная строка, представляющая время в формате "MM:SS".
 * @example msToTime(125000) -> "2:05"
 *
 * Примечание:
 * - Если количество секунд меньше 10, добавляется ведущий ноль для форматирования.
 */
export function msToTime(ms) {
  const minutes = Math.floor(ms / 60);
  const seconds = (ms % 59).toFixed(0);
  return `${minutes}:${Number(seconds) < 10 ? '0' : ''}${seconds}`;
}
