exports.getBuildEnvironment = function () {
  return process.env.GATSBY_BUILD_ENV;
};

exports.getIsSsr = function () {
  return process.env.NODE_ENV === 'production';
};

exports.getApiUrl = function () {
  return process.env.GATSBY_API_URL || '';
};

exports.getAssetUrl = function () {
  return process.env.GATSBY_STATIC_URL || '';
};

exports.getCareerLinks = function () {
  const CAREER_LINK = process.env.GATSBY_CAREER_URL || '';
  const VACANCY_LINK = process.env.GATSBY_VACANCY_URL || '';
  const INTERSHIP_LINK = process.env.GATSBY_INTERSHIP_URL || '';
  const COURSES_SA_LINK = process.env.GATSBY_COURSES_SA_URL || '';
  const COURSES_JAVA_LINK = process.env.GATSBY_COURSES_JAVA_URL || '';
  const COURSES_DE_LINK = process.env.GATSBY_COURSES_DE_URL || '';
  const COURSES_1C_LINK = process.env.GATSBY_COURSES_1C_URL || '';
  const COURSES_DA_LINK = process.env.GATSBY_COURSES_DA_URL || '';
  const COURSES_DEV_1C_LINK = process.env.GATSBY_COURSES_DEV_1C_URL || '';

  return {
    CAREER_LINK,
    VACANCY_LINK,
    INTERSHIP_LINK,
    COURSES_SA_LINK,
    COURSES_JAVA_LINK,
    COURSES_DE_LINK,
    COURSES_1C_LINK,
    COURSES_DA_LINK,
    COURSES_DEV_1C_LINK,
  };
};
