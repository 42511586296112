import React, { useRef, useState } from 'react';
import Lottie from 'lottie-react';
// import { Link as GatsbyLink } from 'gatsby';
// import { Link } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';

import radioLogo from 'assets/animations/radioLogo.json';
import radioLogoWhite from 'assets/animations/radioLogoWhite.json';

export default function RadioLogo() {
  const { isLight } = useBoundStore(headerSelector);
  const lottieRef = useRef(null);
  const [isAnimationLoop] = useState(false);

  return (
    // <Link
    //   as={GatsbyLink}
    //   to="/radio"
    //   role="group"
    //   onMouseEnter={() => {
    //     lottieRef.current.play();
    //     setIsAnimationLoop(true);
    //   }}
    //   onMouseLeave={() => {
    //     lottieRef.current.stop();
    //     setIsAnimationLoop(false);
    //   }}
    // >
    <Lottie
      lottieRef={lottieRef}
      animationData={isLight ? radioLogoWhite : radioLogo}
      loop={isAnimationLoop}
    />
    // </Link>
  );
}
