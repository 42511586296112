export const astonRadioSelector = ({
  tracks,
  activeRadioTrackId,
  initTracks,
  setActiveRadioTrack,
}) => ({
  tracks,
  activeRadioTrackId,
  initTracks,
  setActiveRadioTrack,
});
