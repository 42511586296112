import img_big from 'assets/Radio/rubrics/illustration.png';
import img_medium from 'assets/Radio/newEpisodes/illustration.png';

export const podcastsDataByRubrics = {
  'ASTON Радио': {
    description: 'Рубрика для сотрудников компании и тех, кто интересуется жизнью внутри ASTON',
    img_big,
    img_medium,
  },
  'Расти в ИТ': {
    description: 'Короткие, но содержательные интервью с профессиональными спикерами из мира Айти',
    img_big,
    img_medium,
  },
  'Книга жалоб': {
    description: 'Разбираем реальные проблемы, с которыми сталкиваются айтишники',
    img_big,
    img_medium,
  },
  'До Айти': {
    description: 'Рубрика про сотрудников ASTON, кто до этого работал в самых необычных профессиях',
    img_big,
    img_medium,
  },
  Аудиорепортажи: {
    description: 'Невероятные истории, которые происходят в мире',
    img_big,
    img_medium,
  },
};
