import React, { memo } from 'react';
import { Center } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';
import ThemedImage from 'shared/ThemedImage';

import Play from 'assets/Icons/PlayButton.svg';
import Pause from 'assets/Icons/PauseButton.svg';

const PlayPauseButton = ({ isPlaying, onPlayPauseClick, imgProps = null, ...props }) => {
  const { isLight } = useBoundStore(headerSelector);

  return (
    <Center
      cursor="pointer"
      border="1.6px solid"
      borderColor={`bg.${isLight ? 'white' : 'black'}`}
      borderRadius="full"
      boxSize={{ mobile: '48px', desktop: '60px' }}
      onClick={onPlayPauseClick}
      flexShrink={0}
      transition="all .5s"
      {...props}
    >
      <ThemedImage
        src={isPlaying ? Pause : Play}
        boxSize={{ mobile: '17px', desktop: '24px' }}
        transform={!isPlaying && { mobile: 'translate(1px)', laptop: 'translate(2px)' }}
        {...imgProps}
      />
    </Center>
  );
};

export default memo(PlayPauseButton);
