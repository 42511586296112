import { podcastsDataByRubrics } from 'constants/radioPage/podcastsDataByRubrics';

export const createPodcastsSlice = set => ({
  allPodcasts: {},
  rubrics: {},
  latest: [],
  top8: [],
  activeTrackId: null,

  createRubrics(rubricsData) {
    const _rubrics = rubricsData.reduce((acc, { id, rubric_name }) => {
      if (rubric_name === 'ТОП 8') {
        set(state => {
          state.top8RubricId = id;
        });
        return acc;
      } else {
        acc[rubric_name] = {
          id,
          name: rubric_name,
          episodes: [],
          ...podcastsDataByRubrics[rubric_name],
        };
        return acc;
      }
    }, {});

    set(state => {
      state.rubrics = _rubrics;
    });
  },

  addRubricPodcasts(rubricName, rawPodcastsData) {
    let id = 1;
    const { podcastsArr, podcastsObj } = rawPodcastsData.reduce(
      (acc, podcast) => {
        const uniqId = `${rubricName}-${id}`;

        acc.podcastsArr.push({ ...podcast, id: uniqId });
        acc.podcastsObj[uniqId] = podcast;
        id += 1;

        return acc;
      },
      { podcastsArr: [], podcastsObj: {} },
    );

    set(state => {
      state[rubricName] = podcastsArr;
      state.allPodcasts = { ...state.allPodcasts, ...podcastsObj };
    });
  },

  addRubricsPodcasts(rubricName, rawPodcastsData) {
    let id = 1;
    const { podcastsArr, podcastsObj } = rawPodcastsData.slice(0, 99).reduce(
      (acc, podcast) => {
        const uniqId = `${rubricName}-${id}`;

        acc.podcastsArr.push({ ...podcast, id: uniqId });
        acc.podcastsObj[uniqId] = podcast;
        id += 1;

        return acc;
      },
      { podcastsArr: [], podcastsObj: {} },
    );

    set(state => {
      state.rubrics[rubricName].episodes = podcastsArr;
      state.allPodcasts = { ...state.allPodcasts, ...podcastsObj };
    });
  },

  setActiveTrack(id) {
    set(state => {
      state.activeTrackId = id;
    });
  },
});
