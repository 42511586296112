export const createAstonRadioSlice = set => ({
  tracks: [],
  activeRadioTrackId: 0,
  initTracks(data) {
    set(state => {
      state.tracks = data;
    });
  },
  setActiveRadioTrack(id) {
    set(state => {
      state.activeRadioTrackId = id;
    });
  },
});
